import React, { useState } from "react";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { app } from "../../utils/firebase";

export const Register = (props) => {
  const { handleFormStatusChange } = props;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  const auth = getAuth(app);

  const handleFormChangeLogin = () => {
    handleFormStatusChange("login");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    } else {
      try {
        await createUserWithEmailAndPassword(auth, email, password);
      } catch (error) {
        setError(error.message);
      }
    }
  };

  return (
    <>
      <h1 className="text-center mb-4">Register</h1>
      {error && <p style={{ color: "red" }}>{error}</p>}
      <form onSubmit={handleSubmit} className="mb-4">
        <div className="form-group mb-4">
          {/* <label>Email:</label> */}
          <input
            type="email"
            placeholder="Email"
            className="form-control"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
        <div className="form-group mb-4">
          {/* <label>Password:</label> */}
          <input
            type="password"
            placeholder="Password"
            className="form-control"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </div>
        <div className="form-group mb-4">
          {/* <label>Confirm Password:</label> */}
          <input
            type="password"
            placeholder="Confirm Password"
            className="form-control"
            value={confirmPassword}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
            }}
          />
        </div>
        <button type="submit" className="btn btn-danger">
          Register
        </button>
      </form>
      <p onClick={handleFormChangeLogin}>Login</p>
    </>
  );
};
