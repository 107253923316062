import React, { useState } from "react";
import ProfileModal from "../modals/ProfileModal";

function Profile() {
  const [showModal, setShowModal] = useState(false);

  const handleClick = () => {
    setShowModal(true);
  };

  return (
    <>
      <button
        className="btn btn-dark btn-square"
        onClick={handleClick}
        style={{ width: "-webkit-fill-available" }}
      >
        Profile
      </button>
      <ProfileModal showModal={showModal} setShowModal={setShowModal} />
    </>
  );
}

export default Profile;
