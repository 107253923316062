import React, { useRef } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

function ProfileModal(props) {
  const { showModal, setShowModal } = props;
  const outerDivRef = useRef(null);
  return (
    <div
      ref={outerDivRef}
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        display: showModal ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 100,
        backgroundColor: "rgba(0, 0, 0, .75)", // Add a semi-transparent background color
      }}
      onClick={() => {
        setShowModal(false);
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1 col-sm-12 col-lg-6 offset-lg-3">
            <div className="card" onClick={(e) => e.stopPropagation()}>
              <div className="card-header d-flex justify-content-end">
                <FontAwesomeIcon
                  className="closeTimes"
                  onClick={() => {
                    setShowModal(false);
                  }}
                  icon={faTimes}
                  style={{
                    cursor: "pointer",
                    fontSize: "40px",
                  }} // Set the font size to 48px
                />
              </div>
              <div className="card-body">
                <h1>Profile</h1>
              </div>
              <div className="card-footer d-flex justify-content-end">
                <button
                  className="btn btn-danger"
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileModal;
