import React, { useState } from "react";
import { signInWithEmailAndPassword, getAuth } from "firebase/auth";
import { app } from "../../utils/firebase";

export function Login(props) {
  const { handleFormStatusChange } = props;
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const auth = getAuth(app);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      // Navigation is handled by the onAuthStateChanged listener
    } catch (error) {
      if (error.code === "auth/invalid-credential") {
        setError("Invalid Credentials");
      } else if (error.code === "auth/too-many-requests") {
        setError("Too many requests. Try again later");
      }

      console.error("Error signing in with password and email", error);
    }
  };

  return (
    <>
      <h1 className="text-center mb-4">Login</h1>
      {error && <p style={{ color: "red" }}>{error}</p>}
      <form onSubmit={handleSubmit} className="mb-4">
        <div className="form-group mb-4">
          {/* <label>Email:</label> */}
          <input
            type="email"
            placeholder="Email"
            className="form-control"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
        <div className="form-group ">
          {/* <label>Password:</label> */}
          <input
            type="password"
            placeholder="Password"
            className="form-control"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </div>
        <p
          className="mb-4"
          onClick={() => {
            handleFormStatusChange("forgot");
          }}
        >
          Forgot Password
        </p>
        <button type="submit" className="btn btn-danger">
          Login
        </button>
      </form>
      <p
        onClick={() => {
          handleFormStatusChange("register");
        }}
      >
        Register
      </p>
    </>
  );
}
