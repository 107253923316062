import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faCog } from "@fortawesome/free-solid-svg-icons";

function Note() {
  const [code, setCode] = useState(null);
  const handleGenerateCode = () => {
    // Handle generate code event here
    setCode("123456");
  };

  return (
    <div className="container mt-4 mb-2">
      <div className="Note rounded p-3">
        <div className="input-group mb-3">
          <input
            type="text"
            className="form-control col-9"
            placeholder="Title"
          />
          {/* If no code, generate code button instead */}
          <div className="btn-group col-3 p-0 " role="group">
            {code ? (
              <>
                <button
                  className="btn btn-danger w-10 mt-0 btn-left"
                  type="button"
                >
                  <FontAwesomeIcon icon={faCog} />
                </button>
                <button type="button" className="btn btn-danger w-80 mt-0">
                  {code}
                </button>
                <button
                  className="btn btn-danger w-10 mt-0 btn-right"
                  type="button"
                >
                  <FontAwesomeIcon icon={faCopy} />
                </button>
              </>
            ) : (
              <button
                type="button"
                className="btn btn-danger w-75 mt-0"
                onClick={handleGenerateCode}
              >
                Generate Code
              </button>
            )}
          </div>
        </div>

        <textarea
          className="form-control h-100"
          rows="10"
          placeholder="Write your note here... This project is still in development."
          maxLength={500000}
          disabled
        ></textarea>
        <div className="mt-0">
          <button type="button" className="btn btn-danger mt-0">
            This note will be saved for 24 hours and is only be editable on this
            device
          </button>
        </div>
      </div>
    </div>
  );
}

export default Note;
