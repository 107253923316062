import React, { useState } from "react";

export const Forgot = (props) => {
  const { handleFormStatusChange } = props;
  const [email, setEmail] = useState("");

  const handleFormChangeLogin = () => {
    handleFormStatusChange("login");
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your forgot password logic here
  };

  return (
    <>
      <h1 className="text-center mb-4">Forgot Password</h1>
      <form onSubmit={handleSubmit} className="mb-4">
        <div className="form-group mb-4">
          {/* <label>Email:</label> */}
          <input
            type="email"
            placeholder="Email"
            className="form-control"
            value={email}
            onChange={handleEmailChange}
          />
        </div>
        <button type="submit" className="btn btn-danger">
          Submit
        </button>
      </form>
      <p onClick={handleFormChangeLogin}>Login</p>
    </>
  );
};
