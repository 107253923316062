import { getAuth, signOut } from "firebase/auth";
import { app } from "../../utils/firebase"; // Adjust the path as necessary

function Logout() {
  //   const navigate = useNavigate();
  const auth = getAuth(app);

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        // Redirect to login or any other page after successful logout
      })
      .catch((error) => {
        // Handle any errors during logout
        console.error("Logout failed", error);
      });
  };

  // Optionally, render nothing or a loading indicator
  return (
    <button className="btn btn-danger btn-square" onClick={handleLogout}>
      Logout
    </button>
  );
}

export default Logout;
