import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Sidebar from "./components/Sidebar";
import Note from "./components/Note";
import bgImage from "./bg.png"; // Import the bg.jpg image

import { AuthProvider } from "./utils/AuthContext";

function App() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => setIsOpen(!isOpen);

  return (
    <AuthProvider>
      <div
        className="App d-flex h-100"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
        <div className={`content flex-grow-1  ${isOpen ? "mr-custom" : ""}`}>
          <Note />
        </div>
      </div>
    </AuthProvider>
  );
}

export default App;
