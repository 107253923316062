// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBIbMcZ-WrAcNFqcJy6BUiM3ElANgj8TrY",
  authDomain: "pvtcopy-23cab.firebaseapp.com",
  projectId: "pvtcopy-23cab",
  storageBucket: "pvtcopy-23cab.appspot.com",
  messagingSenderId: "407803378267",
  appId: "1:407803378267:web:cd474f2bcf938f5bbf5d7e",
  measurementId: "G-KFZ81X7J4F",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
