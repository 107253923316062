import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AuthForm from "./Auth";
import History from "./History";
import { useAuth } from "../utils/AuthContext";

// import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

function Sidebar(props) {
  const { currentUser } = useAuth();
  console.log(currentUser);
  const { isOpen, toggleSidebar } = props;

  //   const handleClick = () => {
  //     // Handle click event here
  //     console.log("List item clicked");
  //   };

  return (
    <div
      className={`sidebar bg-dark ${isOpen ? "opened" : "closed"}`}
      style={{ overflow: "visible" }}
    >
      <div
        className="sidebar-toggle"
        style={{ position: "absolute", left: "250px", width: "0px" }}
        onClick={toggleSidebar}
      >
        <button
          className="btn btn-danger h-100 mt-0"
          style={{ borderRadius: "0px 50px 50px 0px" }}
        >
          <FontAwesomeIcon
            icon={isOpen ? faChevronLeft : faChevronRight}
            style={{ fontSize: "40px" }} // Set the font size to 48px
          />
        </button>
      </div>

      {/* Sidebar content goes here */}
      <div className="text-light" style={{}}>
        {currentUser ? <History /> : <AuthForm />}
      </div>
    </div>
  );
}

export default Sidebar;
