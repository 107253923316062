import React, { useState } from "react";
import { Login } from "./AuthForms/Login";
import { Register } from "./AuthForms/Register";
import { Forgot } from "./AuthForms/Forgot";

const AuthForm = () => {
  const [formStatus, setFormStatus] = useState("login"); // Add this line

  const handleFormStatusChange = (newStatus) => {
    setFormStatus(newStatus);
  };
  let form = null;
  switch (formStatus) {
    case "login":
      form = <Login handleFormStatusChange={handleFormStatusChange} />;
      break;
    case "register":
      form = <Register handleFormStatusChange={handleFormStatusChange} />;
      break;
    case "forgot":
      form = <Forgot handleFormStatusChange={handleFormStatusChange} />;
      break;
    default:
      form = <Login handleFormStatusChange={handleFormStatusChange} />;
  }

  return <div className="p-4 mt-4">{form}</div>;
};

export default AuthForm;
