import React from "react";
import Logout from "./AuthForms/Logout";
import Profile from "./AuthForms/Profile";
const History = () => {
  const notes = ["Note 1", "Note 2", "Note 3"];

  return (
    <div style={{ overflow: "scroll" }}>
      <div
        style={{
          height: "95vh",
        }}
        className="pt-4"
      >
        <h1>Notes</h1>
        <ul>
          {notes.map((note, index) => (
            <li key={index}>{note}</li>
          ))}
        </ul>
      </div>
      <div
        className="d-flex justify-content-between align-items-bottom"
        style={{
          height: "5vh",
        }}
      >
        <Profile />

        <Logout />
      </div>
    </div>
  );
};

export default History;
